.table {
    position: relative;
    border: 1px solid var(--color-light-background);
    border-top-width: 0;
    border-radius: 8px;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;

    th,
    td {
        padding: 8px 12px;
    }

    thead {
        th {
            font-weight: bold;
            background-color: var(--color-background);
            position: sticky;
            top: 0;
            border-top: 1px solid var(--color-light-background);
            border-bottom: 2px solid var(--color-primary);
            text-align: left;
    
            &:first-child {
                &:after,
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    width: 8px;
                    height: 8px;
                }
                &:after {
                    border: 0 solid var(--color-light-background);
                    border-top-width: 1px;
                    border-left-width: 1px;
                    border-top-left-radius: 8px;
                }
                &:before {
                    background-color: var(--color-background);
                }
            }
    
            &:last-child {
                &:after,
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    width: 8px;
                    height: 8px;
                }
                &:after {
                    border: 0 solid var(--color-light-background);
                    border-top-width: 1px;
                    border-right-width: 1px;
                    border-top-right-radius: 8px;
                }
                &:before {
                    background-color: var(--color-background);
                }
            }
        }
    }
    
    tbody {
    }
    
}
