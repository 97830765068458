@mixin color-variables {
    --color-text: black;
    --color-text-light: #F4F0E9;

    --color-background: white;

    --color-primary: #eab652;

    --color-light-background: black;

    @media print {
        --color-background: white;
    }
}

$color-text: var(--color-text);
$color-text-light: var(--color-text-light);

$color-background: var(--color-background);

$color-primary: var(--color-primary);

$color-light-background: var(--color-light-background);
